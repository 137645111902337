<template>
  <!-- 导入客户信息 -->
  <div style="margin-left: 10px">
    <el-button type="primary" @click="uploadFileProp()">导入</el-button>

    <el-dialog
      title="导入信息"
      width="800px"
      center
      :close-on-click-modal="false"
      :visible.sync="dataFormVisible"
    >
      <el-form ref="dataForm">
        <!-- <el-form-item label="数据模板：" label-width="110px">
          <el-link type="primary" :href="templateUrl">下载模板</el-link>
        </el-form-item> -->
        <el-form-item label="文件：" label-width="110px">
          <el-upload
            :action="`${
              $store.state.baseUrl
            }db-sample-data/importDbSampleData/${this.getUserId()}`"
            :on-success="uploadSuccess"
            :on-error="uploadError"
            :before-upload="fileBeforeUpload"
            :auto-upload="false"
            :multiple="false"
            :format="['xlsx', 'xls']"
            accept=".xls,.xlsx"
            ref="uploadChannelUserData"
            :headers="$store.state.uploadHeaders"
          >
            <el-button type="primary">选择文件</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dataFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dataFormSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["bodyData"],
  data() {
    return {
      templateUrl:
        "https://minio.ardentbiomed.com.cn/lims/template/客户信息导入模板.xlsx",
      dataFormVisible: false,
    };
  },

  created() {},
  methods: {
    uploadFileProp() {
      if (this.$refs.uploadChannelUserData)
        this.$refs.uploadChannelUserData.clearFiles();
      this.dataFormVisible = true;
    },
    dataFormSubmit() {
      if (
        this.$refs.uploadChannelUserData &&
        this.$refs.uploadChannelUserData.uploadFiles.length > 0
      ) {
        this.saveLoading = this.$loading({
          lock: true,
          text: "正在上传文件，请稍等......",
          spinner: "el-icon-loading",
        });
        setTimeout(() => {
          this.$refs.uploadChannelUserData.submit();
        }, 200);
      } else {
        this.$message({
          message: "请上传文件",
          type: "error",
        });
      }
    },
    fileBeforeUpload() {
      this.saveLoading = this.$loading({
        lock: true,
        text: "导入中，请稍后...",
        spinner: "el-icon-loading",
      });
    },
    uploadSuccess(res) {
      this.saveLoading.close();
      if (res.code == 200) {
        this.$message({
          message: "导入成功",
          type: "success",
        });
        this.dataFormVisible = false;
        this.$emit("refreshDataList");
      } else {
        this.$refs.uploadChannelUserData.clearFiles();
        if (res.code == 400 && res.msg == "errorModel") {
          let msgArr = res.data;
          let msg = "";
          msgArr.forEach((item) => {
            msg += `<div style="word-break:break-all;">${item.msg}：${item.data}</div>`;
          });
          this.$message({
            dangerouslyUseHTMLString: true,
            message: msg,
            type: "error",
            showClose: true,
            duration: 6000,
          });
        } else {
          if (res.msg.indexOf("Token") != -1) {
            this.$message({
              type: "warning",
              message: res.msg,
            });
            this.$logout();
            return;
          }
          this.$message({
            message: res.msg,
            type: "error",
            duration: 0,
            showClose: true,
          });
        }
      }
    },
    uploadError(res) {
      this.$refs.uploadChannelUserData.clearFiles();
      this.saveLoading.close();
      this.$message({
        message: res.msg,
        type: "error",
        duration: 0,
        showClose: true,
      });
    },
  },
};
</script>
<template>
  <!-- 新增/修改客户信息 -->
  <el-dialog
    :title="dataForm.id ? '修改' : '新增'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    center
    width="800px"
    v-loading="dialogLoadingFlag"
  >
    <el-form
      ref="dataForm"
      :rules="dataRule"
      :model="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="130px"
    >
      <el-form-item label="Sample ID" prop="sampleId">
        <el-input
          v-model.trim="dataForm.sampleId"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input v-model.trim="dataForm.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="DNA生物学年龄" prop="dnaBiologicalAge">
        <el-input
          v-model.trim="dataForm.dnaBiologicalAge"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="出生日期" prop="birthday">
        <el-date-picker
          style="width: 100%"
          v-model="dataForm.birthday"
          type="date"
          placeholder="请选择"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="birthToAge"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="衰老指数" prop="agingIndex">
        <el-input
          v-model.trim="dataForm.agingIndex"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="您DNA水平超过" prop="dnaLevelThreshold">
        <el-input
          v-model.trim="dataForm.dnaLevelThreshold"
          placeholder="请输入"
          style="width: 120px"
        >
          <template slot="append">%</template></el-input
        >
      </el-form-item>

      <el-form-item label="代谢指数为" prop="metabolismIndex">
        <el-input
          v-model.trim="dataForm.metabolismIndex"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="DNA生物学年龄比" prop="dnaBiologicalAgeRatio">
        <el-input
          v-model.trim="dataForm.dnaBiologicalAgeRatio"
          placeholder="请输入"
          style="width: 120px"
        >
          <template slot="append">%</template></el-input
        >
      </el-form-item>
      <el-form-item label="采样时间" prop="samplingTime">
        <el-date-picker
          style="width: 100%"
          v-model="dataForm.samplingTime"
          type="datetime"
          placeholder="请选择"
          format="yyyy-MM-dd  HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="报告时间" prop="reportTime">
        <el-date-picker
          style="width: 100%"
          v-model="dataForm.reportTime"
          type="datetime"
          placeholder="请选择"
          format="yyyy-MM-dd  HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :loading="loadingFlag"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {},
      dataRule: {},
      dialogLoadingFlag: false,
      loadingFlag: false,
    };
  },
  created() {},
  methods: {
    init(id) {
      this.visible = true;
      this.dataForm = {
        status: 1,
        cashSettlement: true,
      };
      if (id) {
        this.dialogLoadingFlag = true;
        this.$http.get(`/db-sample-data/baseGetById/${id}`).then((res) => {
          this.dialogLoadingFlag = false;
          if (res.data.code == 200) {
            let data = res.data.data;
            if (data) {
              if (data.dnaLevelThreshold)
                data.dnaLevelThreshold = Math.round(
                  data.dnaLevelThreshold * 100
                );
              if (data.dnaBiologicalAgeRatio)
                data.dnaBiologicalAgeRatio = Math.round(
                  data.dnaBiologicalAgeRatio * 100
                );
              this.dataForm = data;
            }
          }
        });
      }
    }, //根据出生日期计算年龄
    birthToAge(birthday) {
      if (birthday) {
        birthday = birthday.split("-");
        // 新建日期对象
        let date = new Date();
        // 今天日期，数组，同 birthday
        let today = [date.getFullYear(), date.getMonth() + 1, date.getDate()];
        // 分别计算年月日差值
        let age = today.map((val, index) => {
          return val - birthday[index];
        });
        // 当天数为负数时，月减 1，天数加上月总天数
        if (age[2] < 0) {
          // 简单获取上个月总天数的方法，不会错
          let lastMonth = new Date(today[0], today[1], 0);
          age[1]--;
          age[2] += lastMonth.getDate();
        }
        // 当月数为负数时，年减 1，月数加上 12
        if (age[1] < 0) {
          age[0]--;
          age[1] += 12;
        }

        this.$set(this.dataForm, "age", age[0]);
      }
    },

    /**
     * 上传合同成功
     */
    uploadSuccess(res) {
      this.$store.state.beforeUploadLoading.close();
      this.$set(this.dataForm, "contractUrl", res.data);
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let url = "/db-sample-data/saveOrUpdate";
          let data = JSON.parse(JSON.stringify(this.dataForm));
          if (data.dnaLevelThreshold && Number(data.metabolismIndex) != "NaN") {
            data.dnaLevelThreshold = data.dnaLevelThreshold / 100;
          }
          if (
            data.dnaBiologicalAgeRatio &&
            Number(data.dnaBiologicalAgeRatio) != "NaN"
          ) {
            data.dnaBiologicalAgeRatio = data.dnaBiologicalAgeRatio / 100;
          }
          this.loadingFlag = true;
          this.$http.post(`${url}`, data).then((res) => {
            this.loadingFlag = false;
            if (res.data.code == 200) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.visible = false;
              this.$emit("refreshDataList");
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
